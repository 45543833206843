<template>
  <video
    :key="URL"
    v-if="video"
    :class="additionalClasses"
    :autoplay="autoplay"
    :loop="loop"
    :muted="muted"
    :playsinline="playsinline"
    :data-wf-ignore="dataWfIgnore"
    :data-object-fit="dataObjectFit"
    :poster="poster"
  >
    <source :type="type" :src="URL" data-wf-ignore="true" />
  </video>

  <img
    loading="lazy"
    v-else
    :src="URL"
    :width="width"
    :height="height"
    :alt="alt"
    :class="additionalClasses"
  />
</template>

<script setup lang="ts">
import ImageKit from "imagekit-javascript";
import config from "@/config";
import { computed } from "vue";

const props = defineProps({
  path: {
    type: String,
    required: true,
  },
  width: {
    type: String,
    required: false,
  },
  height: {
    type: String,
    required: false,
  },
  alt: {
    type: String,
    required: false,
  },
  circle: {
    type: Boolean,
    required: false,
  },

  additionalClasses: {
    type: String,
    default: "",
  },

  //   video
  video: {
    type: Boolean,
    default: false,
  },
  autoplay: {
    type: Boolean,
    required: false,
  },
  loop: {
    type: Boolean,
    required: false,
  },
  muted: {
    type: Boolean,
    default: true,
  },
  playsinline: {
    type: Boolean,
    required: false,
  },
  dataObjectFit: {
    type: String,
    required: false,
  },
  dataWfIgnore: {
    type: Boolean,
    required: false,
  },
  poster: {
    type: String,
    required: false,
  },
});

const imagekit = new ImageKit({
  urlEndpoint: config("imageKitUrl"),
});

const transformation: any = {
  height: props.height,
  width: props.width,
};

if (!props.video) {
  transformation["defaultImage"] = "fallback.svg";
} else {
  transformation["cropMode"] = "pad_resize";
}

if (props.circle) {
  transformation["radius"] = "max";
}

const type = computed(() => {
  if (props.video && props.path.split(".").pop()) {
    if (props.path.split(".").pop() == "quicktime") return "video/mp4";
    return "video/" + props.path.split(".").pop();
  }
  return undefined;
});

const URL = computed(() =>
  imagekit.url({
    path: props.path,
    transformation: [transformation],
  }),
);
</script>
